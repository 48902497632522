export const mpo: Player [] = [
    { id: 0,  place: "1",  name: "Bryce Kraushar",  points: 703  },
    { id: 1,  place: "2",  name: "Marshall Toews",  points: 687  },
    { id: 2,  place: "3",  name: "Eric Giesbrecht",  points: 629  },
    { id: 3,  place: "4",  name: "Bryan Freese",  points: 527  },
    { id: 4,  place: "5",  name: "Jason The",  points: 481  },
    { id: 5,  place: "6",  name: "Brad Gerbrandt",  points: 377  },
    { id: 6,  place: "7",  name: "Devin Koop",  points: 365  },
    { id: 7,  place: "8",  name: "Nick Briere",  points: 361  },
    { id: 8,  place: "9",  name: "Josh Logozar",  points: 314  },
    { id: 9,  place: "10",  name: "Robbie Friesen",  points: 282  },
    { id: 10,  place: "11",  name: "Mike Evans",  points: 233  },
    { id: 11,  place: "12",  name: "Jonathan Vandenberg",  points: 209  },
    { id: 12,  place: "13",  name: "Erik Wiebe",  points: 204  },
    { id: 13,  place: "14",  name: "Kevin Friesen",  points: 141  },
    { id: 14,  place: "15",  name: "Terrell Feakes",  points: 131  },
    { id: 15,  place: "16",  name: "Kevin Desrosiers",  points: 126  },
    { id: 16,  place: "17",  name: "Colin McDonell",  points: 123  },
    { id: 17,  place: "18",  name: "Robert Logan",  points: 120  },
    { id: 18,  place: "19",  name: "Keith Robb",  points: 111  },
    { id: 19,  place: "20",  name: "Stirling Reimer",  points: 103  },
    { id: 20,  place: "21",  name: "Jean-Paul Match",  points: 91  },
    { id: 21,  place: "22",  name: "Jake Johnson",  points: 82  },
    { id: 22,  place: "23",  name: "Clint Bueckert",  points: 57  },
    { id: 23,  place: "24",  name: "Xavier Tomanek",  points: 49  },
    { id: 24,  place: "25",  name: "Jay Toews",  points: 48  },
    { id: 25,  place: "26",  name: "Patrick Savard",  points: 47  },
    { id: 26,  place: "27",  name: "Chadrick Doerksen",  points: 44  },
    { id: 27,  place: "28",  name: "Sam Meggs",  points: 43  },
    { id: 28,  place: "29",  name: "Justin Desserre",  points: 30  },
    { id: 29,  place: "30",  name: "Brandon Drozd",  points: 10  },
    ]
    
    export const fpo: Player [] = [
    ]
    
    export const ma1: Player [] = [
    { id: 0,  place: "1",  name: "Timothy Farrell",  points: 625  },
    { id: 1,  place: "2",  name: "Sunny Dhillon",  points: 596  },
    { id: 2,  place: "3",  name: "Michael Casey",  points: 549  },
    { id: 3,  place: "4",  name: "David Harrison",  points: 541  },
    { id: 4,  place: "5",  name: "Glenn Larmour",  points: 459  },
    { id: 5,  place: "6",  name: "Jonathan Villaverde",  points: 352  },
    { id: 6,  place: "7",  name: "Kurtis Tallaire",  points: 349  },
    { id: 7,  place: "8",  name: "David Wiebe",  points: 327  },
    { id: 8,  place: "9",  name: "Mark Eldridge",  points: 286  },
    { id: 9,  place: "10",  name: "Aaron Magian",  points: 266  },
    { id: 10,  place: "11",  name: "DeShawn Klassen",  points: 243  },
    { id: 11,  place: "12",  name: "David Sims",  points: 217  },
    { id: 12,  place: "13",  name: "Dan Edinborough",  points: 202  },
    { id: 13,  place: "14",  name: "Corey Reddecopp",  points: 181  },
    { id: 14,  place: "15",  name: "Brandon Drozd",  points: 173  },
    { id: 15,  place: "16",  name: "Tyler Kehler",  points: 168  },
    { id: 16,  place: "17",  name: "Thomas Fehr",  points: 160  },
    { id: 17,  place: "18",  name: "Colin McDonell",  points: 151  },
    { id: 18,  place: "19",  name: "Kevin Talgoy",  points: 122  },
    { id: 19,  place: "20",  name: "Graham Sereda",  points: 108  },
    { id: 20,  place: "21",  name: "Tom Friesen",  points: 91  },
    { id: 21,  place: "22",  name: "Cody Buhler",  points: 87  },
    { id: 22,  place: "23",  name: "Steve Link",  points: 84  },
    { id: 23,  place: "24",  name: "Christopher Gottzmann",  points: 78  },
    { id: 24,  place: "25",  name: "Dean Cowan",  points: 40  },
    { id: 25,  place: "25",  name: "Andy Noel",  points: 40  },
    { id: 26,  place: "27",  name: "Jonah Funk",  points: 25  },
    { id: 27,  place: "28",  name: "Carlos Barkman",  points: 10  },
    { id: 28,  place: "28",  name: "Gerard McGonigal",  points: 10  },
    ]
    
    export const fa1: Player [] = [
    { id: 0,  place: "1",  name: "Annika Wiebe",  points: 128  },
    { id: 1,  place: "2",  name: "Cam Tang",  points: 113  },
    { id: 2,  place: "3",  name: "Brittany Peters",  points: 91  },
    { id: 3,  place: "4",  name: "Sharon Enns",  points: 84  },
    { id: 4,  place: "5",  name: "Taylor Linsenmeier",  points: 45  },
    { id: 5,  place: "6",  name: "Shawna Scott",  points: 33  },
    { id: 6,  place: "7",  name: "Roberta Ronald",  points: 26  },
    { id: 7,  place: "8",  name: "Sarah Mitchell",  points: 20  },
    ]
    
    export const ma2: Player [] = [
    { id: 0,  place: "1",  name: "Airyn Clark",  points: 1072  },
    { id: 1,  place: "2",  name: "Anton Sawka",  points: 974  },
    { id: 2,  place: "3",  name: "Trevor Menard",  points: 925  },
    { id: 3,  place: "4",  name: "Zachary Desrochers",  points: 755  },
    { id: 4,  place: "5",  name: "Cameron Ward",  points: 721  },
    { id: 5,  place: "6",  name: "Justin Cummer",  points: 714  },
    { id: 6,  place: "7",  name: "Everett Guenther",  points: 676  },
    { id: 7,  place: "8",  name: "Alex MacNeil",  points: 654  },
    { id: 8,  place: "9",  name: "Joel Vankoughnet",  points: 605  },
    { id: 9,  place: "10",  name: "Chris Earnshaw",  points: 585  },
    { id: 10,  place: "11",  name: "Riley Gauthier",  points: 550  },
    { id: 11,  place: "12",  name: "Alex Loewen",  points: 465  },
    { id: 12,  place: "13",  name: "Tyler Halmrast",  points: 454  },
    { id: 13,  place: "14",  name: "Gabriel Reimer",  points: 411  },
    { id: 14,  place: "14",  name: "Blake Derksen",  points: 411  },
    { id: 15,  place: "16",  name: "Trevor Hnatowich",  points: 391  },
    { id: 16,  place: "17",  name: "Annika Wiebe",  points: 361  },
    { id: 17,  place: "18",  name: "Tyson Fehr",  points: 305  },
    { id: 18,  place: "19",  name: "Kevin Wiebe",  points: 301  },
    { id: 19,  place: "19",  name: "Brad Hamm",  points: 301  },
    { id: 20,  place: "21",  name: "Jeff Knight",  points: 288  },
    { id: 21,  place: "22",  name: "Gregory Blatz",  points: 281  },
    { id: 22,  place: "23",  name: "Johnny Elias",  points: 271  },
    { id: 23,  place: "24",  name: "Joseph Menno",  points: 261  },
    { id: 24,  place: "25",  name: "Greg Lane",  points: 254  },
    { id: 25,  place: "26",  name: "Jim Fraser",  points: 231  },
    { id: 26,  place: "27",  name: "Zachary Hildebrand",  points: 201  },
    { id: 27,  place: "27",  name: "Jason Froese",  points: 201  },
    { id: 28,  place: "27",  name: "Joel Toews",  points: 201  },
    { id: 29,  place: "27",  name: "Jason Hosler",  points: 201  },
    { id: 30,  place: "31",  name: "Eric Kulchycki",  points: 173  },
    { id: 31,  place: "32",  name: "Todd Kauk",  points: 161  },
    { id: 32,  place: "33",  name: "Sam Ritchot",  points: 156  },
    { id: 33,  place: "34",  name: "Steven Black",  points: 141  },
    { id: 34,  place: "34",  name: "Matt Friesen",  points: 141  },
    { id: 35,  place: "34",  name: "Garnet B",  points: 141  },
    { id: 36,  place: "37",  name: "Matt Klosse",  points: 129  },
    { id: 37,  place: "38",  name: "Jeri-Ann Brownbridge",  points: 111  },
    { id: 38,  place: "38",  name: "Josh Puhach",  points: 111  },
    { id: 39,  place: "40",  name: "Thomas Krause",  points: 105  },
    { id: 40,  place: "41",  name: "Martin Gisiger",  points: 101  },
    { id: 41,  place: "42",  name: "Joshua Toews",  points: 92  },
    { id: 42,  place: "42",  name: "Ryan Fielding",  points: 92  },
    { id: 43,  place: "44",  name: "Sean Booth",  points: 91  },
    { id: 44,  place: "44",  name: "Karl Dirks",  points: 91  },
    { id: 45,  place: "46",  name: "Terrell Wiebe",  points: 82  },
    { id: 46,  place: "47",  name: "Dustin Wolfe",  points: 77  },
    { id: 47,  place: "47",  name: "Vincent Froese",  points: 77  },
    { id: 48,  place: "49",  name: "Kyle Watanabe",  points: 71  },
    { id: 49,  place: "50",  name: "Dylan Ferris",  points: 53  },
    { id: 50,  place: "51",  name: "Jaxson Wallace",  points: 50  },
    { id: 51,  place: "52",  name: "Nich Lovatt",  points: 42  },
    { id: 52,  place: "53",  name: "Bryan Klassen",  points: 30  },
    { id: 53,  place: "53",  name: "Scott Loeppky",  points: 30  },
    { id: 54,  place: "55",  name: "Jeff Burns",  points: 29  },
    { id: 55,  place: "56",  name: "Joseph Madden",  points: 20  },
    { id: 56,  place: "57",  name: "Katie Sereda",  points: 10  },
    { id: 57,  place: "57",  name: "Dustin Hunt",  points: 10  },
    ]
    
    export const fa2: Player [] = [
    { id: 0,  place: "1",  name: "Maya Woods",  points: 90  },
    { id: 1,  place: "2",  name: "Susan Evans",  points: 47  },
    { id: 2,  place: "3",  name: "Sarah Mitchell",  points: 34  },
    { id: 3,  place: "4",  name: "Dinah Talgoy",  points: 30  },
    { id: 4,  place: "5",  name: "Roberta Ronald",  points: 10  },
    { id: 5,  place: "5",  name: "Julie Badiou",  points: 10  },
    ]
    
    export const ma3: Player [] = [
    { id: 0,  place: "1",  name: "Bradley Zakaluk",  points: 910  },
    { id: 1,  place: "2",  name: "Sam Ritchot",  points: 879  },
    { id: 2,  place: "3",  name: "Paul Jaarsma",  points: 830  },
    { id: 3,  place: "4",  name: "Dawson Wiens",  points: 628  },
    { id: 4,  place: "5",  name: "Gordon Merkel",  points: 517  },
    { id: 5,  place: "6",  name: "Kendall Pries",  points: 514  },
    { id: 6,  place: "7",  name: "Brandon Boyce",  points: 467  },
    { id: 7,  place: "8",  name: "Lucas Van Den Bosch",  points: 460  },
    { id: 8,  place: "9",  name: "Corey Harrison",  points: 435  },
    { id: 9,  place: "10",  name: "Rory McIntyre",  points: 415  },
    { id: 10,  place: "11",  name: "Nicholas Sorin",  points: 388  },
    { id: 11,  place: "12",  name: "Ryan Havens",  points: 325  },
    { id: 12,  place: "13",  name: "Kaden Choptuik",  points: 288  },
    { id: 13,  place: "14",  name: "Tyler Halmrast",  points: 252  },
    { id: 14,  place: "15",  name: "Trevor Hnatowich",  points: 242  },
    { id: 15,  place: "16",  name: "Jeff Knight",  points: 238  },
    { id: 16,  place: "17",  name: "Chad Baxter",  points: 237  },
    { id: 17,  place: "18",  name: "Tarik Bara",  points: 202  },
    { id: 18,  place: "19",  name: "Caelan Giesbrecht",  points: 186  },
    { id: 19,  place: "20",  name: "Cody Zimmer",  points: 180  },
    { id: 20,  place: "21",  name: "Alex Loewen",  points: 175  },
    { id: 21,  place: "22",  name: "Timmy Kroeker",  points: 172  },
    { id: 22,  place: "23",  name: "Brodie Goertzen",  points: 163  },
    { id: 23,  place: "24",  name: "Jaxson Wallace",  points: 161  },
    { id: 24,  place: "25",  name: "Glenn Murillo",  points: 160  },
    { id: 25,  place: "26",  name: "Nicholas Johnson",  points: 153  },
    { id: 26,  place: "27",  name: "Mike Desmarais",  points: 152  },
    { id: 27,  place: "27",  name: "Ryan Desmarais",  points: 152  },
    { id: 28,  place: "29",  name: "Henry Wood",  points: 144  },
    { id: 29,  place: "30",  name: "Anthony Kroeker",  points: 142  },
    { id: 30,  place: "30",  name: "Jordan Schmidt",  points: 142  },
    { id: 31,  place: "32",  name: "Dylan Ferris",  points: 133  },
    { id: 32,  place: "32",  name: "Ivan Detchev",  points: 133  },
    { id: 33,  place: "34",  name: "Daniel Emberg",  points: 128  },
    { id: 34,  place: "35",  name: "Joshua Toews",  points: 121  },
    { id: 35,  place: "36",  name: "Jason Pappel",  points: 113  },
    { id: 36,  place: "37",  name: "Blake Walchuk",  points: 112  },
    { id: 37,  place: "38",  name: "Andrew Ridd",  points: 93  },
    { id: 38,  place: "39",  name: "Alfred Rubio",  points: 80  },
    { id: 39,  place: "40",  name: "Nathan Fischer",  points: 67  },
    { id: 40,  place: "41",  name: "Joshua Lazarenko",  points: 64  },
    { id: 41,  place: "42",  name: "Josh Thordarson",  points: 61  },
    { id: 42,  place: "43",  name: "Duarte Campos",  points: 47  },
    { id: 43,  place: "44",  name: "Dustin Hunt",  points: 43  },
    { id: 44,  place: "45",  name: "Tyler Belog",  points: 36  },
    { id: 45,  place: "45",  name: "Sebastian Lindberg",  points: 36  },
    { id: 46,  place: "47",  name: "Keelan Funk",  points: 32  },
    { id: 47,  place: "47",  name: "Michael McIntee",  points: 32  },
    { id: 48,  place: "49",  name: "Christopher Daeninck",  points: 31  },
    { id: 49,  place: "50",  name: "Riley Cowan",  points: 22  },
    { id: 50,  place: "51",  name: "Blake Peter",  points: 10  },
    { id: 51,  place: "51",  name: "Jamie Wilson",  points: 10  },
    { id: 52,  place: "51",  name: "Ryan Woods",  points: 10  },
    { id: 53,  place: "51",  name: "Sam Kuhl",  points: 10  },
    ]
    
    export const fa3: Player [] = [
    { id: 0,  place: "1",  name: "Bailey Hildebrand",  points: 40  },
    { id: 1,  place: "2",  name: "Dinah Talgoy",  points: 10  },
    { id: 2,  place: "2",  name: "Julie Badiou",  points: 10  },
    ]
    
    export const ma40: Player [] = [
    { id: 0,  place: "1",  name: "Dan Edinborough",  points: 356  },
    { id: 1,  place: "2",  name: "Benjamin Hanka",  points: 278  },
    { id: 2,  place: "3",  name: "Jim Fraser",  points: 250  },
    { id: 3,  place: "4",  name: "Kevin Talgoy",  points: 230  },
    { id: 4,  place: "5",  name: "Airyn Clark",  points: 223  },
    { id: 5,  place: "6",  name: "Jeff Burns",  points: 168  },
    { id: 6,  place: "7",  name: "Derek Bishop",  points: 164  },
    { id: 7,  place: "8",  name: "John Frommelt",  points: 140  },
    { id: 8,  place: "9",  name: "Steve Link",  points: 101  },
    { id: 9,  place: "10",  name: "Brock Martens",  points: 81  },
    { id: 10,  place: "11",  name: "Joel Toews",  points: 66  },
    { id: 11,  place: "12",  name: "Arlen Nickel",  points: 45  },
    { id: 12,  place: "13",  name: "Chad Richardson",  points: 42  },
    { id: 13,  place: "14",  name: "stephen chesnut",  points: 25  },
    { id: 14,  place: "15",  name: "David Link",  points: 10  },
    { id: 15,  place: "15",  name: "Blaine Dueck",  points: 10  },
    { id: 16,  place: "15",  name: "Adam Chainey",  points: 10  },
    ]
    
    export const fa40: Player [] = [
    { id: 0,  place: "1",  name: "Susan Evans",  points: 135  },
    { id: 1,  place: "2",  name: "Roberta Ronald",  points: 107  },
    { id: 2,  place: "3",  name: "Jessica Benoit",  points: 59  },
    { id: 3,  place: "4",  name: "Andrea Michaud",  points: 31  },
    { id: 4,  place: "5",  name: "Dinah Talgoy",  points: 20  },
    { id: 5,  place: "6",  name: "Karen Schlichting",  points: 10  },
    ]
    
    export const ma50: Player [] = [
    { id: 0,  place: "1",  name: "Clark Greenfield",  points: 269  },
    { id: 1,  place: "2",  name: "David Unger",  points: 187  },
    { id: 2,  place: "3",  name: "Paul Reder",  points: 118  },
    { id: 3,  place: "4",  name: "Derek Bishop",  points: 80  },
    { id: 4,  place: "5",  name: "Blair McComb",  points: 62  },
    { id: 5,  place: "6",  name: "Aiden Enns",  points: 52  },
    { id: 6,  place: "7",  name: "Tim Barkley",  points: 45  },
    { id: 7,  place: "8",  name: "Lorne York",  points: 43  },
    { id: 8,  place: "9",  name: "Ed Hoeppner",  points: 30  },
    { id: 9,  place: "10",  name: "Christopher Istace",  points: 24  },
    { id: 10,  place: "11",  name: "David Duchesne",  points: 23  },
    { id: 11,  place: "12",  name: "Matt Kauenhofen",  points: 20  },
    { id: 12,  place: "13",  name: "Jeff Burns",  points: 10  },
    { id: 13,  place: "13",  name: "Jerry K",  points: 10  },
    { id: 14,  place: "13",  name: "Greg Hanec",  points: 10  },
    ]
    
    export const fa50: Player [] = [
    { id: 0,  place: "1",  name: "Roberta Ronald",  points: 10  },
    ]
    
    
    